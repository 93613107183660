import React from 'react';

import { Row, Col, Heading, Blockquote, Line, Words } from 'arwes';

class WorkEntry extends React.Component {
    render() {
        return (<>
            <Line animate layer='control' />
            <Row animate>
                <Col s={12} m={2}>
                    <Heading animate node='h4'>{this.props.position} @ <Words layer="control">{this.props.company}</Words></Heading>
                    <Heading animate node='h6'>{this.props.start}</Heading>
                    <Heading animate node='h6'>{this.props.end}</Heading>
                </Col>
                
                <Col s={12} m={10} style={{ fontSize: '18px' }}>
                    <Blockquote animate>
                        <ul>
                            { this.props.children }
                        </ul>
                    </Blockquote>
                </Col>
            </Row>
        </>);
    }
};

export default WorkEntry;