import React from 'react';

import WorkEntry from '../components/workEntry';
import Layout from '../components/layout';
import { Heading, Frame, Line } from 'arwes';

import Loading from '../components/loading';

class Work extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
          loading: true
        };
    }

    componentDidMount() {
        this.setState({
            loading: false
        });
    }
    
    render() {
        if (this.state.loading) {
            return (<Loading />);
        }
        
        return (<Layout>
            <div style={{ maxWidth: "400px", paddingBottom: "40px" }}>
                <Frame animate layer='primary' corners={4}>
                    {anim => (
                        <div style={{paddingTop: "10px"}}>
                            <Heading animate show={anim.entered} node='h1'>Work</Heading>
                        </div>
                    )}
                </Frame>
            </div>

            <div style={{ padding: "20px" }}>
                <WorkEntry position="APM Intern" company="Google" start="May 2019" end="Aug 2019">
                    <li>Built a reference design to set the stylus standard for Chromebooks for the next 5 years</li>
                    <li>Researched competitors, pain points with engineers, and user studies on education users</li>
                    <li>Convinced 3 senior PMs of new method for vendors to propose new devices to Chrome OS</li>
                    <li>Proposed a family of styluses and innovated new mechanism to save 4-8% of total cost</li>
                </WorkEntry>

                <WorkEntry position="Teaching Assistant" company="Harvard University" start="Sep 2017" end="May 2019">
                    <li>Taught for 10-15 hours a week (up to 50 students at a time); 10 individual sessions a semester</li>
                    <li>1 of 5 out of 80 course staff to receive Harvard’s Teaching with Distinction Award</li>
                    <li>Published 10 live stream videos (cryptocurrency, k-means, Kali) with ~50k views on YouTube</li>
                    <li>Delivered 2 live lectures (Keras/Tensorflow & Django) with total 40k+ views on YouTube</li>
                </WorkEntry>

                <WorkEntry position="Algorithms Developer" company="Beijing Genomics Institute" start="Jun 2018" end="Aug 2018">
                    <li>Revised 3 100-line key scripts in the team’s existing pipeline, reducing usage errors by 100%</li>
                    <li>Reduced legacy scripts into one script with 2x the arguments and a 50% reduction in lines of code</li>
                    <li>Submitted 2 pull requests to a key open source library, adding error handling and arguments</li>
                    <li>Created a 4 part software pipeline (100 LOC each) to analyze genome data via supercomputer</li>
                </WorkEntry>
                

                <Line animate layer='control' />
            </div>
        </Layout>);
    }
}

export default Work;